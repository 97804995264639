


















































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { readAdminUsers } from '@/store/admin/getters';
import { dispatchGetUsers } from '@/store/admin/actions';

import { ttt } from '@/nutils';

interface IPagination {
  rowsPerPage: number;
  totalItems: number;
  page: number;
};

@Component
export default class AdminUsers extends Vue {
  public ttt = ttt;
  public pagination: IPagination = { rowsPerPage: 10, totalItems: 0, page: 1, };

  public headers = [
    { text: ttt('Email'), sortable: true, value: 'email', align: 'left', },
    { text: ttt('First Name'), sortable: true, value: 'first_name', align: 'left', },
    { text: ttt('Last Name'), sortable: true, value: 'last_name', align: 'left', },
    { text: ttt('Is Active'), sortable: true, value: 'isActive', align: 'left', },
    { text: ttt('Is Superuser'), sortable: true, value: 'isSuperuser', align: 'left', },
    { text: ttt('Actions'), value: 'id', },
  ];

  get pages() {
    if (this.pagination.totalItems === 0)
        this.pagination.totalItems = this.users.length;
    return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }

  get users() {
    return readAdminUsers(this.$store);
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
  }

  public cancel() {
    this.$router.back();
  }
}
